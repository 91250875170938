import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Maintanance from "./pages/Maintanance";

import Privacy from "./pages/Privacy";
import "semantic-ui-css/semantic.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Terms from "./pages/Terms";
function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />

        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/" element={<Maintanance />} /> */}
          <Route path="/:id" element={<Landing />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
