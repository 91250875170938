import React, { useEffect, useMemo, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lightlogo from "../Assets/Logo-light.png";
import Darklogo from "../Assets/Logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import Select from 'react-select'
import countryList from 'react-select-country-list'

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const theme = useTheme();

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };



  const initialFormValue = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
    country: "",
    phonenumber: "",
    countrycode: ""
  };

  const location = useLocation();



  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [
    phonenumbervallidaiton,
    setphonenumbervallidaiton,
    phonenumbervallidaitonref,
  ] = useState(false);

  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [countryCode, setCountryCode] = useState('us');  // State for country code
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");

  const [ref_details, setref, ref_detailsref] = useState("");

  const {
    name,
    email,
    password,
    confirmPassword,
    referral_code,
    phonenumber,
    country,
  } = formValue;

  useEffect(() => {
    var currURL = window.location.href;
    var refferalId = currURL.split("invite=")[1];
    console.log(refferalId, "refferalId")
    // formValue.referral_code = refferalId;
    setFormValue((st) => ({
      ...st,
      referral_code: refferalId,
    }));
    console.log(formValue.referral_code, "formValue.referral_code")
    if(refferalId != null)
    {
      find_parent(refferalId);
    }
    const params = new URLSearchParams(location.search);
    const emailFromQuery = params.get("email");
    if (emailFromQuery) {
      console.log(emailFromQuery, "emailFromQuery");
      setFormValue((st) => ({
        ...st,
        email: emailFromQuery,
      }));
    }
  }, [0]);

  const find_parent = async(code) =>{
    try {
      var obj = {
        referralCode: code,
      };
      var data = {
        apiUrl: apiService.find_parent,
        payload: obj,
      };
      var resp = await postMethod(data);
      if(resp.status)
      {
        setref(resp.data);
      }
    } catch (error) {
      
    }
  }

  const [siteLoader, setSiteLoader] = useState(false);

  const navigate = useNavigate();

  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    if(name == "referral_code")
    {
      if(value != null)
      {
        find_parent(value);
      }

    }
    validate(formData);
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };


  const changeHandler = value => {
    setValue(value)
    console.log(value.label)
    formValue["country"] = value.label

    const countryCode = value?.value || 'IN';
    formValue["phonenumber"] = countryCode
    formValue["countrycode"] = countryCode;

    setCountryCode(countryCode.toLowerCase());
    console.log(countryCode, "jdcnudwhiudwiewiw");
  }

  const validate = (values) => {
    let errors = {};
    const username = values.email.split("@")[0];

    if (!values.name) {
      errors.name = "Name is a required field!";
      setnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.name)) {
      errors.name = "Username must start with a letter!";
      setnameValidate(true);
    } else if (values.name.length < 3 || values.name.length > 25) {
      errors.name = "Name must have 3 to 25 characters!";
      setnameValidate(true);
    } else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
      errors.name = "Username can only contain letters, numbers, and underscores!";
      setnameValidate(true);
    } else if (!values.email) {
      setnameValidate(false);
      errors.email = "Email is a required field!";
      setemailValidate(true);


    } else if (!/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    }
    // else if (username.length < 4 || username.length > 30) {
    //   errors.email = "Email Username must be between 4-30 characters long";
    //   setemailValidate(true);
    // } 
    else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email = "Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    }
    //  else if (values.email.length > 30) {
    //   errors.email = "Email address is too long!";
    //   setemailValidate(true);
    // } 
    else if (!values.password) {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required!";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = "Password should not be below 5 or above 25 letters!";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one lowercase character!";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one uppercase character!";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one digit!";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character!";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm password is a required field!";
      setconfirmPasswordValidate(true);
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and Confirm password do not match!";
      setconfirmPasswordValidate(true);
    } else if (!values.phonenumber) {
      console.log(values.phonenumber, "phonenumber");
      setphonenumbervallidaiton(true);
      errors.phonenumber = "Phone number is a required field!";
    } else {
      console.log(values.phonenumber, "phonenumber");

      console.log(values, "values");

      const phoneNumber = parsePhoneNumberFromString(values.phonenumber, values.countrycode);

      console.log(phoneNumber, "phonenumber");

      if (!phoneNumber || !phoneNumber.isValid()) {
        errors.phonenumber = "Invalid phone number!";
        setphonenumbervallidaiton(true);
      } else if (!values.country) {
        errors.country = "Country is a required field!";
      } else if (values.country !== NewFormcountry) {
        errors.phonenumber = "Country and Phone Number mismatch!";
        setphonenumbervallidaiton(true);
      } else if (!Terms) {
        setconfirmPasswordValidate(false);
        errors.terms = "Terms and conditions must be accepted!";
        setTermsValidation(true);
      } else {
        errors = {};
        setnameValidate(false);
        setemailValidate(false);
        setpasswordValidate(false);
        setconfirmPasswordValidate(false);
        setphonenumbervallidaiton(false);
        setTermsValidation(false);
      }
    }

    setvalidationnErr(errors);
    return errors;
  };


  // const validate = (values) => {
  //   let errors = {};
  //   const username = values.email.split("@")[0];

  //   if (!values.name) {
  //     errors.name = "Name is a required field !";
  //     setnameValidate(true);
  //   } else if (!/^[a-zA-Z]/.test(values.name)) {
  //     errors.name = "Username must start with a letter !";
  //     setnameValidate(true);
  //   } else if (values.name.length < 3 || values.name.length > 25) {
  //     errors.name = "Name must have an  3 to 25 characters !";
  //     setnameValidate(true);
  //   } else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
  //     errors.name =
  //       "Username can only contain letters, numbers, and underscores !";
  //     setnameValidate(true);
  //   } else if (!values.email) {
  //     setnameValidate(false);
  //     errors.email = "Email is a required field!";
  //     setemailValidate(true);
  //   } else if (
  //     !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
  //   ) {
  //     errors.email = "Invalid email address!";
  //     setemailValidate(true);
  //   } else if (username.length < 4 || username.length > 30) {
  //     errors.email = "Email Username must be between 4-30 characters long";
  //     setemailValidate(true);
  //   } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
  //     errors.email =
  //       " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
  //     setemailValidate(true);
  //   } else if (!/[a-zA-Z]/.test(username)) {
  //     errors.email = "Email username must contain at least one letter (a-z)";
  //     setemailValidate(true);
  //   } else if (values.email.length > 50) {
  //     errors.email = "Email address is too long!";
  //     setemailValidate(true);
  //   } else if (values.password == "") {
  //     setemailValidate(false);
  //     setpasswordValidate(true);
  //     errors.password = "Password is required !";
  //   } else if (values.password.length < 5 || values.password.length > 25) {
  //     setpasswordValidate(true);
  //     errors.password = "Password should not below 5 above 25 letters !";
  //   } else if (!values.password.match(/[a-z]/g)) {
  //     setpasswordValidate(true);
  //     errors.password = "Please enter at least lower character !";
  //   } else if (!values.password.match(/[A-Z]/g)) {
  //     setpasswordValidate(true);
  //     errors.password = "Please enter at least upper character !";
  //   } else if (!values.password.match(/[0-9]/g)) {
  //     setpasswordValidate(true);
  //     errors.password = "Please enter at One digit character !";
  //   } else if (!values.password.match(/[!@#$%^&*]/g)) {
  //     setpasswordValidate(true);
  //     errors.password = "Please enter at least one special character !";
  //   } else if (!values.confirmPassword) {
  //     setpasswordValidate(false);
  //     errors.confirmPassword = "Confirm password is a required field";
  //     setconfirmPasswordValidate(true);
  //   } else if (
  //     values.password &&
  //     values.confirmPassword &&
  //     values.password !== values.confirmPassword
  //   ) {
  //     errors.confirmPassword = "Password and Confirm password does not match";
  //     setconfirmPasswordValidate(true);
  //   } else if (!phonenumber) {

  //     const phoneNumber = parsePhoneNumberFromString(values.phonenumber);
  //     if (!phoneNumber || !phoneNumber.isValid()) {
  //       errors.phonenumber = "Invalid phone number!";
  //       setphonenumbervallidaiton(true);
  //     } else if (phoneNumber.country !== values.country) {
  //       errors.phonenumber = "Country and phone number mismatch!";
  //       setphonenumbervallidaiton(true);
  //     } else {
  //       setphonenumbervallidaiton(false);
  //     }

  //     // setphonenumbervallidaiton(true);
  //     // errors.phonenumber = "PhoneNumber is a required field ";
  //   } 
  //   else if(NewFormcountry !== value ){
  //     setphonenumbervallidaiton(true);
  //     errors.phonenumber = "Country and Phone Number is Mismatch ";
  //   }
  //   else if (!Terms) {
  //     setconfirmPasswordValidate(false);
  //     errors.terms = "Terms is a required field ";
  //     setTermsValidation(true);
  //   } else {
  //     errors.terms = "";
  //     setconfirmPasswordValidate(false);
  //     setpasswordValidate(false);
  //     setemailValidate(false);
  //     setnameValidate(false);

  //     setTermsValidation(false);
  //   }
  //   setvalidationnErr(errors);

  //   return errors;
  // };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    let errros = validate(formValue);

    formValue["UUID"] = createdUuid;
    if (
      nameValidateref.current === false &&
      emailValidateref.current === false &&
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false &&
      Terms == true
    ) {
      var obj = {
        userName: formValue.name,
        email: formValue.email,
        password: formValue.password,
        confirmPassword: formValue.confirmPassword,
        mobile_no: formValue.phonenumber,
        country: formValue.country,
        referral: formValue.referral_code,
      };
      var data = {
        apiUrl: apiService.signup,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // setFormValue(initialFormValue);


      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        formValue.name = "";
        formValue.email = "";
        formValue.password = "";
        formValue.confirmPassword = "";
        formValue.phonenumber = "";
        formValue.country = "";
      } else {
        formValue.name = "";
        formValue.email = "";
        formValue.password = "";
        formValue.confirmPassword = "";
        formValue.phonenumber = "";
        formValue.country = "";
        toast.error(resp.Message);
      }
    } else {
    }
  };

  // const location = useLocation();

  // useEffect(() => {
  //   var currURL = window.location.href;
  //   var refferalId = currURL.split("invite=")[1];
  //   console.log(refferalId,"refferalId")
  //   formValue.referral_code = refferalId;
  //   console.log(formValue.referral_code,"formValue.referral_code")
  //   const params = new URLSearchParams(location.search);
  //   const emailFromQuery = params.get("email");
  //   if (emailFromQuery) {
  //     console.log(emailFromQuery, "emailFromQuery");
  //     setFormValue((st) => ({
  //       ...st,
  //       email: emailFromQuery,
  //     }));
  //   }
  // }, [location]);

  const [NewFormNMobileCode, setNewFormNMobileCode] = useState();
  const [NewFormMobileValue, setNewFormMobileValue] = useState();
  const [NewFormcountry, setNewFormcountry] = useState();
  const handleNewFormMobile = (value, countryObj, e, formattedValue) => {

    console.log(value, countryObj, e, formattedValue);
    setNewFormMobileValue(value);
    setNewFormNMobileCode(countryObj.dialCode);
    setNewFormcountry(countryObj.name);
    formValue["newformmobilecode"] = countryObj.dialCode;
    formValue["phonenumber"] = value;
    // formValue["country"] = countryObj.name;
    // console.log(formValue,"formvalue")

    validate(formValue);
  };

  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])



  const nav_page = () => {
    navigate("/register")
  };


  return (
    <>
      <header className="scrolled">
        <div className="">
          <div className="header_new_mega">
            <div className={`${classes.root} `}>
              <AppBar position="static">
                <Toolbar className="container">
                  <Typography variant="h6" className={classes.title}>
                    <div className="logo_new_sectio d-flex">
                      <a href="/" className="logo-brand">
                        <img
                          src={Lightlogo}
                          className="img-fluid "
                          alt="logo"
                        />
                      </a>
                      <div className={`menu_new_typr ${classes.appBarItems}`}>
                        <a
                          href="/"
                        >
                          Home
                        </a>

                      </div>
                    </div>
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                  >
                    <MenuIcon onClick={handleMobileMenuOpen} />
                  </IconButton>
                  <div className={`menu_new_typr ${classes.appBarItems}`}>
                    <Button className="nav-btn" onClick={nav_page}>Register</Button>
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                className={classes.drawer}
                onClose={() => handleMobileMenuClose(false)}
              >
                <div className={classes.mobileMenu}>
                  <div className="logo_new_sectio mobile_menu_icon">
                    <Link to="/" className="logo-brand">
                      <img
                        src={Darklogo}
                        className="img-fluid m-3"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="menu_statis">
                    <ListItem button className="drawa">
                      <a href="#home">Home</a>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Button onClick={() => navigate("/register")} >Register</Button>
                    </ListItem>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </header>
      <div className="Rifa-landing">
        <div className="section1 bg-white" id="home">
          <div className="container">
            <div className="row my-5 daj">
              <div className="col-lg-6">
                <img src={require("../Assets/Sign-up.png")} className="d-block mx-auto" width="80%" />
              </div>
              <div className="col-lg-6">
                <div className="card_login register_page_login_card">
                  <h3 className="mb-3">Create an account</h3>
                  <div>
                    <label className="lable_text">Name</label>
                    <div class="form_login_input">
                      <input
                        type="text"
                        name="name"
                        autocomplete="off"
                        minLength={3}
                        maxLength={25}
                        value={name}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Name"
                      />
                      {validationnErr && validationnErr.name && (
                        <p className="errorcss">{validationnErr.name}</p>
                      )}
                    </div>

                    <label className="lable_text">Email</label>
                    <div class="form_login_input">
                      <input
                        type="text"
                        autocomplete="off"
                        name="email"
                        // maxLength="30"
                        // max="30"
                        value={email}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Email"
                      />
                      {validationnErr && validationnErr.email && (
                        <p className="errorcss">{validationnErr.email}</p>
                      )}
                    </div>

                    <label className="lable_text ">Password</label>
                    <div class="form_login_input password_border">
                      <input
                        type={inputType}
                        name="password"
                        value={password}
                        minLength={6}
                        maxLength={15}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Password"
                      />

                      <div className="eye_content">
                        {passHide == true ? (
                          <i
                            class="fa-regular fa-eye reg_eye"
                            onClick={() => passwordHide("show")}
                          ></i>
                        ) : (
                          <i
                            class="fa-regular fa-eye-slash reg_eye"
                            onClick={() => passwordHide("hide")}
                          ></i>
                        )}
                      </div>
                      {validationnErr && validationnErr.password && (
                        <p className="errorcss">{validationnErr.password}</p>
                      )}
                    </div>

                    <label className="lable_text">Confirm Password</label>
                    <div class="form_login_input password_border">
                      <input
                        autocomplete="off"
                        type={inputTypeconf}
                        name="confirmPassword"
                        value={confirmPassword}
                        minLength={6}
                        maxLength={15}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Confirm Password"
                      />

                      <div className="eye_content">
                        {passHidconf == true ? (
                          <i
                            class="fa-regular fa-eye reg_eye"
                            onClick={() => passwordHideconf("show")}
                          ></i>
                        ) : (
                          <i
                            class="fa-regular fa-eye-slash reg_eye"
                            onClick={() => passwordHideconf("hide")}
                          ></i>
                        )}
                      </div>
                      {validationnErr && validationnErr.confirmPassword && (
                        <p className="errorcss">
                          {validationnErr.confirmPassword}
                        </p>
                      )}
                    </div>
                    <label className="lable_text">Country</label>

                    <Select options={options} value={value} onChange={changeHandler} />

                    <label className="lable_text">Phone Number</label>

                    <PhoneInput
                      // country={"us"}
                      country={countryCode}  // Set country code here

                      enableSearch={true}
                      // value={mobileValue}
                      // onChange={setMobileValue}
                      class="phoneInput"
                      onChange={(value, countryObj, e, formattedValue) =>
                        handleNewFormMobile(
                          value,
                          countryObj,
                          e,
                          formattedValue
                        )
                      }
                    />
                    {validationnErr && validationnErr.phonenumber && (
                      <p className="errorcss">
                        {validationnErr.phonenumber}
                      </p>
                    )}
                    <label className="lable_text">Refferal code</label>
                    <div class="form_login_input">
                      <input
                        type="text"
                        name="referral_code"
                        autocomplete="off"
                        minLength={3}
                        maxLength={25}
                        value={referral_code}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Refferal code ( Optional )"
                      />
                    </div>

                    {ref_detailsref.current != null && ref_detailsref.current != "" ? (
                      <>
                       <label className="lable_text">Refferal username</label>
                       <div class="form_login_input">
                         <input
                           type="text"
                           autocomplete="off"
                           value={ref_detailsref.current.username}
                           class="form-control"
                           disabled
                         />
                       </div>
                      </>
                    ) : ("")}

                   

                    <div class="custom-control custom-checkbox  mb-3 mt-4 d-flex">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck"
                        value={isChecked}
                        name="check"
                        onChange={handleTerms}
                      />
                      <label class="custom-control-label" for="customCheck">
                        I agree to the{" "}
                        <Link to="/terms-conditions" target="_blank">
                          Terms and Condition
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </label>
                    </div>
                    {termsValidation ? (
                      <p className="errorcss">
                        Terms and Conditions are required
                      </p>
                    ) : (
                      ""
                    )}

                    <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button className="w-100" onClick={formSubmit}>
                          Create Account
                        </Button>
                      ) : (
                        <Button className="w-100">Loading ...</Button>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
