import React, { useEffect, useContext } from "react";
import { gsap } from "gsap";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lightlogo from "../Assets/Logo-light.png";
import Darklogo from "../Assets/Logo.png";
import './MaintenanceNotification.css'; // Import the CSS file for styling

import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    appBarItems: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    mobileMenuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    drawerIcon: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    drawer: {
        width: 300,
    },
}));

function Landing() {
    useEffect(() => {
        gsap.to(".circle", {
            duration: 5,
            repeat: -1,
            motionPath: {
                path: "#animationPath",
                align: "#animationPath",
                alignOrigin: [0.5, 0.5],
            },
        });
    }, []);

    const navigate = useNavigate();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const theme = useTheme();

    const classes = useStyles();

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(false);
    };

    useEffect(() => {
        var currURL = window.location.href;
        var refferalId = currURL.split("/")[3];

        console.log(refferalId, "jnniucnnidc");
        console.log(refferalId, "jijijmomjifdm");
        if (refferalId != undefined && refferalId != "") {
            var id = refferalId;
            verifyId(id);
        }
    }, [0]);

    const verifyId = async (id) => {
        try {
            var obj = {
                id: id,
            };
            var data = {
                apiUrl: apiService.verifyId,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                toast.success(resp.Message);
            } else {
                toast.error(resp.Message);
            }
        } catch (err) { }
    };

    const nav_page = () => {
        navigate("/register");
    };

    return (
        <>
            <header className="scrolled">
                <div className="">
                    <div className="header_new_mega">
                        <div className={`${classes.root} `}>
                            <AppBar position="static">
                                <Toolbar className="container">
                                    <Typography variant="h6" className={classes.title}>
                                        <div className="logo_new_sectio d-flex">
                                            <a href="/" className="logo-brand">
                                                <img
                                                    src={Lightlogo}
                                                    className="img-fluid "
                                                    alt="logo"
                                                />
                                            </a>
                                        </div>
                                    </Typography>
                                </Toolbar>
                            </AppBar>

                        </div>
                    </div>
                </div>
            </header>
            <div className="Rifa-landing">
                <div className="sec1-main">
                    <div className="section1" id="home">
                        <div className="container">
                            <div className="row m-100 relative daj">
                                <img
                                    src={require("../Assets/banner.png")}
                                    className="absolute"
                                />
                                <svg
                                    width="1140"
                                    height="342"
                                    viewBox="0 0 1142 344"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        id="path1"
                                        d="M1101 172L598 172"
                                        stroke="url(#paint0_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path2"
                                        d="M598 156.627C884.217 165.634 861.239 8.50589 1103 1"
                                        stroke="url(#paint1_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path3"
                                        d="M598 186.373C884.217 177.366 861.239 334.494 1103 342"
                                        stroke="url(#paint2_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path4"
                                        d="M598 164.817C872.494 169.856 886.469 69.0746 1141 82.1762"
                                        stroke="url(#paint3_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path5"
                                        d="M598 179.181C872.494 174.202 886.469 273.783 1141 260.838"
                                        stroke="url(#paint4_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />

                                    <circle r="5" fill="url(#paint5_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="15s">
                                            <mpath href="#path1" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint6_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="14s">
                                            <mpath href="#path2" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint7_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="13s">
                                            <mpath href="#path3" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint8_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="12s">
                                            <mpath href="#path4" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint9_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="11s">
                                            <mpath href="#path5" />
                                        </animateMotion>
                                    </circle>

                                    <path
                                        id="path6"
                                        d="M41 172L544 172"
                                        stroke="url(#paint10_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path7"
                                        d="M544 187.373C257.783 178.366 280.761 335.494 39 343"
                                        stroke="url(#paint11_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path8"
                                        d="M544 157.627C257.783 166.634 280.761 9.50589 39 2"
                                        stroke="url(#paint12_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path9"
                                        d="M544 179.183C269.506 174.144 255.531 274.925 1 261.824"
                                        stroke="url(#paint13_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />
                                    <path
                                        id="path10"
                                        d="M544 164.819C269.506 169.798 255.531 70.2166 1 83.1622"
                                        stroke="url(#paint14_linear_1825_5965)"
                                        stroke-opacity="0.5"
                                        stroke-width="1.5"
                                        stroke-linecap="square"
                                    />

                                    <circle r="5" fill="url(#paint15_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="11s">
                                            <mpath href="#path6" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint16_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="12s">
                                            <mpath href="#path7" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint17_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="13s">
                                            <mpath href="#path8" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint18_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="14s">
                                            <mpath href="#path9" />
                                        </animateMotion>
                                    </circle>
                                    <circle r="5" fill="url(#paint19_linear_1825_5965)">
                                        <animateMotion repeatCount="indefinite" dur="15s">
                                            <mpath href="#path10" />
                                        </animateMotion>
                                    </circle>

                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_1825_5965"
                                            x1="1101"
                                            y1="172"
                                            x2="598"
                                            y2="172"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_1825_5965"
                                            x1="598.499"
                                            y1="156.627"
                                            x2="1106.15"
                                            y2="2.01255"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#236AFF" />
                                            <stop offset="1" stop-color="#6E9CFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint2_linear_1825_5965"
                                            x1="598.5"
                                            y1="186"
                                            x2="1103.16"
                                            y2="341.613"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#236AFF" />
                                            <stop offset="1" stop-color="#6E9CFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint3_linear_1825_5965"
                                            x1="598"
                                            y1="165.321"
                                            x2="1143.23"
                                            y2="83.2322"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#236AFF" />
                                            <stop offset="1" stop-color="#6E9CFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint4_linear_1825_5965"
                                            x1="598"
                                            y1="179"
                                            x2="1140.94"
                                            y2="262.034"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#236AFF" />
                                            <stop offset="1" stop-color="#6E9CFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint5_linear_1825_5965"
                                            x1="825"
                                            y1="231"
                                            x2="815"
                                            y2="231"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint6_linear_1825_5965"
                                            x1="869"
                                            y1="219"
                                            x2="859"
                                            y2="219"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint7_linear_1825_5965"
                                            x1="896"
                                            y1="172"
                                            x2="886"
                                            y2="172"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint8_linear_1825_5965"
                                            x1="869"
                                            y1="127"
                                            x2="859"
                                            y2="127"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint9_linear_1825_5965"
                                            x1="825"
                                            y1="116"
                                            x2="815"
                                            y2="116"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint10_linear_1825_5965"
                                            x1="41"
                                            y1="172"
                                            x2="544"
                                            y2="172"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint11_linear_1825_5965"
                                            x1="544"
                                            y1="187.373"
                                            x2="39"
                                            y2="343"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#236AFF" />
                                            <stop offset="1" stop-color="#6E9CFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint12_linear_1825_5965"
                                            x1="544"
                                            y1="157.627"
                                            x2="39"
                                            y2="2"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint13_linear_1825_5965"
                                            x1="544"
                                            y1="179.183"
                                            x2="1"
                                            y2="261.824"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint14_linear_1825_5965"
                                            x1="544"
                                            y1="164.819"
                                            x2="1"
                                            y2="83.1622"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint15_linear_1825_5965"
                                            x1="825"
                                            y1="231"
                                            x2="815"
                                            y2="231"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint16_linear_1825_5965"
                                            x1="869"
                                            y1="219"
                                            x2="859"
                                            y2="219"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint17_linear_1825_5965"
                                            x1="896"
                                            y1="172"
                                            x2="886"
                                            y2="172"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint18_linear_1825_5965"
                                            x1="869"
                                            y1="127"
                                            x2="859"
                                            y2="127"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint19_linear_1825_5965"
                                            x1="825"
                                            y1="116"
                                            x2="815"
                                            y2="116"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#6E9CFF" />
                                            <stop offset="1" stop-color="#236AFF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="section1" id="home">
                        <div className="container">


                            <div className="row relative daj">

                                <div className="col-lg-12">
                                    <p></p>
                                    <h1>System Under Maintenance
                                    </h1>
                                    <button type="button" data-toggle="modal" data-target="#exampleModalLong">
                                        Know More
                                    </button>


                                    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <div className="content">
                                                        <p><strong className="title">System Maintenance Notification</strong></p>

                                                        <p className="important-update"><strong>Important Update</strong></p>
                                                        <p className="description">
                                                            Our system is currently undergoing scheduled maintenance to add new updated strategies in <strong>DCA & INTRA Bot</strong>,
                                                            enhance security, and mainly integrate the most anticipated <strong>TRIANGULAR & ARBITRAGE Bot</strong>.
                                                        </p>
                                                        <div className="maintenance-details">
                                                            <h1 className="title">Maintenance Duration:</h1>
                                                            <p className="description">72 hours starting from <strong className="">23/10/2024</strong> to <strong className="">26/10/2024</strong></p>
                                                        </div>
                                                        <div className="services-affected">
                                                            <p className="title"> <strong className="text-left">Services Affected:</strong></p>
                                                            <ul>
                                                                <li>▪︎ Update DCA & INTRA BOT strategies</li>
                                                                <li>▪︎ Data correction</li>
                                                                <li>▪︎ Integration of the most anticipated TRIANGULAR & ARBITRAGE Bot</li>
                                                                <li>▪︎ Add support system</li>
                                                            </ul>
                                                        </div>
                                                        <p className="description">
                                                            We apologize for any inconvenience this may cause and appreciate your patience as we work to enhance our system.
                                                        </p>
                                                        <div className="estimated-time mt-4">
                                                            <p  ><strong className="title">Estimated Completion Time:</strong></p>
                                                            <h1 className="title">3 Days</h1>

                                                        </div>
                                                        <p className="description">Thank you for your understanding.</p>
                                                        <p className="footer-text">Best regards,</p>
                                                        <p className="footer-text">Rifa Management</p>
                                                    </div>
                                                </div>

                                                <div className="modal-footer">
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">Close</span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Landing;
